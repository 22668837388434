import {
  createContext,
  type PropsWithChildren,
  useContext,
  useEffect,
  useRef,
} from 'react';
import {
  AmbientLight,
  Color,
  type ColorRepresentation,
  type Object3D,
  Scene as ThreeScene,
} from 'three';
import { TrackballControls } from '@/utils/scans/trackballControls';
import { getDirectionalLights } from './utils';

export interface SceneProviderProps extends PropsWithChildren {
  backgroundColor?: ColorRepresentation;
}

type SceneValue = {
  scene: ThreeScene;
  controls: TrackballControls | undefined;
  setControls: (controls: TrackballControls) => void;
  updateControls: () => void;
  addToScene: (...obj: Object3D[]) => void;
  clearScene: () => void;
  resetCamera: () => void;
};

const SceneContext = createContext<SceneValue>({
  scene: new ThreeScene(),
  controls: undefined,
  setControls: () => null,
  updateControls: () => null,
  addToScene: () => null,
  clearScene: () => null,
  resetCamera: () => null,
});

export default function SceneProvider({
  backgroundColor = 0xdedede,
  children,
}: SceneProviderProps) {
  const sceneRef = useRef(new ThreeScene());
  const controlsRef = useRef<TrackballControls>();

  useEffect(() => {
    sceneRef.current.background = new Color(backgroundColor);
    addToScene(new AmbientLight(0xdedede));
    getDirectionalLights().forEach((light) => addToScene(light));

    return () => {
      sceneRef.current.children = [];
      controlsRef.current?.dispose();
    };
  }, []);

  const setControls = (controls: TrackballControls) => {
    controlsRef.current = controls;
  };

  const updateControls = () => controlsRef.current?.update();

  const addToScene = (...obj: Object3D[]) => {
    sceneRef.current.add(...obj);
  };

  const clearScene = () => {
    sceneRef.current.children = sceneRef.current.children.filter(({ type }) =>
      ['AmbientLight', 'DirectionalLight'].includes(type)
    );
  };

  const resetCamera = () => {
    controlsRef.current?.reset();
  };

  const contextValue = {
    scene: sceneRef.current,
    controls: controlsRef.current,
    setControls,
    updateControls,
    addToScene,
    clearScene,
    resetCamera,
  };

  return (
    <SceneContext.Provider value={contextValue}>
      {children}
    </SceneContext.Provider>
  );
}

export function useScene() {
  const context = useContext(SceneContext);
  if (!context) {
    throw Error('useScene must be used within a SceneProvider');
  }
  return context;
}
